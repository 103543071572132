import React, { useState, useEffect, Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// import LandingPage from '../LandingPage/LandingPage';
import Registration from '../../components/Registration/Registration';
import ImportersRegistration from '../../components/Registration/Importers/Registration';
import HachvanaRegistration from '../../components/Registration/Hachvana/Registration';
import HachvanaUserExist from '../../components/Registration/Hachvana/UserExists';
import RegistrationStatus from '../../components/Registration/RegistrationStatus';
import UserExist from '../../components/Registration/UserExists';
import Login from '../../components/Login/Login';
import ForgotPassword from '../../components/Login/ForgotPassword';
import QuestionandAnswer from '../../components/Login/QuestionandAnswer';
import SMS from '../../components/Login/SMS';
import SMSFromLogin from '../../components/Login/SMSFromLogin';
import SecStepRegistration from '../../components/Registration/SecStepRegistration';
import Page404 from '../../components/Page404';
// import StatusOK from '../../components/statusok';
import { CircularProgress } from "@material-ui/core";
import AppProvider from '../../AppProvider';
import Logout from '../../components/Logout';
import ErrorPage from '../../components/ErrorPage';

const RoutingManager = () => {
  const [isDispatcher, setIsDispatcher] = useState(false);
  const [action, setAction] = useState(null);


  useEffect(() => {

    console.log('RoutingManager:', `${process.env.PUBLIC_URL}`);
    setIsDispatcher(window.location.pathname.indexOf("Dispatcher") > 0);
    let actionType = new URLSearchParams(window.location.search).get('TAM_OP');
    if (actionType) {
      actionType = actionType.toLocaleLowerCase();
    }
    setAction(actionType);

  }, []);
  const hachvana = () => {
    return (<Switch>
      <Route path="/UserExist"><HachvanaUserExist /></Route>
      <Route path={`${process.env.PUBLIC_URL}/UserExist`}><HachvanaUserExist /></Route>
      <Route path={`/Registration`}><HachvanaRegistration /></Route>
      <Route path={`${process.env.PUBLIC_URL}/Registration`}><HachvanaRegistration /></Route>
    </Switch>
    );
  }
  const mishpahot = () => {
    return (<Switch>
      <Route path="/UserExist" ><UserExist /></Route>
      <Route path={`${process.env.PUBLIC_URL}/UserExist`}><UserExist /></Route>
      <Route path={`/Registration`}><Registration /></Route>
      <Route path={`${process.env.PUBLIC_URL}/Registration`}><Registration /></Route>
    </Switch>
    );
  }
  //importers:
  const others = () => {
    return (<Switch>
      {/* <Route exect path="/UserExist" ><UserExist /></Route> */}
      <Route path={"/UserExist", `${process.env.PUBLIC_URL}/UserExist`}><UserExist /></Route>
      <Route path={[`/Registration`, `${process.env.PUBLIC_URL}/Registration`]} ><ImportersRegistration /></Route>
      {/* <Route path={`${process.env.PUBLIC_URL}/Registration`}><ImportersRegistration /></Route> */}
    </Switch>
    );
  }

  return (<Router >
    <Switch basename={process.env.PUBLIC_URL} >
    {/* <Route path={[`/statusok`, `${process.env.PUBLIC_URL}/statusok`]}><StatusOK /></Route> */}
      <Route path={[`/RegistrationStatus`, `${process.env.PUBLIC_URL}/RegistrationStatus`]}>
        <RegistrationStatus /></Route>
      <Route path={[`/Login`, `${process.env.PUBLIC_URL}/Login`, '/Dispatcher', `${process.env.PUBLIC_URL}/Dispatcher`]} >
        {isDispatcher
          ? action == 'logout'
            ? <Logout />
            : action == 'error'?<ErrorPage />:<Login />

          : <Page404 />}
      </Route>
      
      <Route path={[`/SMS`, `${process.env.PUBLIC_URL}/SMS`]}><SMS /></Route>
      <Route path={[`/SMSFromLogin`, `${process.env.PUBLIC_URL}/SMSFromLogin`]}><SMSFromLogin /></Route>
      <Route path={[`/ForgotPassword`, `${process.env.PUBLIC_URL}/ForgotPassword`]}><ForgotPassword /></Route>
      <Route path={[`/QandAnswer`, `${process.env.PUBLIC_URL}/QandAnswer`]}><QuestionandAnswer /></Route>
      <Route path={[`/SecStepRegistration`, `${process.env.PUBLIC_URL}/SecStepRegistration`]}><SecStepRegistration /></Route>
      <Route path={["/Page404", `${process.env.PUBLIC_URL}/Page404`]}>
        <Page404 />
      </Route>
      <Route path={["/LOGOUT", `${process.env.PUBLIC_URL}/Logout`]}>
        <Logout />
      </Route>
      {isDispatcher && action == 'error' &&
        <Route>
          <ErrorPage />
        </Route>
      }
      {window.location.origin.indexOf("hachvana") > 0 ?
        hachvana()
        : window.location.origin.indexOf("mishpahot") > 0 ||  window.location.origin.indexOf("familyonline") > 0 ?
          mishpahot()
          : others()

      }

    </Switch>
  </Router>)
};

// class RoutingManager extends Component {


//   render() {
//     const { loading } = this.context;
//     const hachvana=()=>{
//       return (<Fragment>
//       <Route path="/UserExist" component={HachvanaUserExist} />
//       <Route path={`${process.env.PUBLIC_URL}/UserExist`} component={HachvanaUserExist} />
//       <Route path={`${process.env.PUBLIC_URL}/RegistrationStatus`} component={RegistrationStatus} />
//       <Route path={`${process.env.PUBLIC_URL}/`} component={HachvanaRegistration} />
//       </Fragment>
//       );
//     }
//     const mishpahot=()=>{
//       return (<Fragment>
//         <Route path="/UserExist" component={UserExist} />
//         <Route path={`${process.env.PUBLIC_URL}/UserExist`} component={UserExist} />
//         <Route path={`${process.env.PUBLIC_URL}/RegistrationStatus`} component={RegistrationStatus} />
//         <Route path={`${process.env.PUBLIC_URL}/`} component={Registration} />

//       </Fragment>
//       );
//     }
//     const others=()=>{
//       return (<Fragment>
//         <Route path="/UserExist" component={UserExist} />
//         <Route path={`${process.env.PUBLIC_URL}/UserExist`} component={UserExist} />
//         <Route path={`${process.env.PUBLIC_URL}/RegistrationStatus`} component={RegistrationStatus} />
//         <Route path={`${process.env.PUBLIC_URL}/`} component={ImportersRegistration} />
//       </Fragment>
//       );
//     }

//     return (
//       // if(window.location.origin.indexOf("Dispatcher")> 0)
//       // {

//       // }
//       <Fragment>
//          <Switch> 
//          {/* {window.location.origin.indexOf("hachvana")  > 0 ?  
//          hachvana
//          :window.location.origin.indexOf("mishpahot")  > 0 ? 
//          mishpahot
//          :others
//         } */}
//         <Route path={`${process.env.PUBLIC_URL}/Login`} component={Login}/>
//          {/* <Route path="/UserExist" component={HachvanaUserExist} />
//               <Route path={`${process.env.PUBLIC_URL}/UserExist`} component={HachvanaUserExist} /> */}


//          { window.location.href.indexOf("hachvana")  > 0 ?  
//               <Route path={["/UserExist",`${process.env.PUBLIC_URL}/UserExist`]} component={HachvanaUserExist} />
//             :
//               <Route path={["/UserExist",`${process.env.PUBLIC_URL}/UserExist`]} component={UserExist} />
//           } 
//           <Route path={`${process.env.PUBLIC_URL}/RegistrationStatus`} component={RegistrationStatus} />
//             {window.location.origin.indexOf("mishpahot")  > 0 ?  
//           <Route path={`${process.env.PUBLIC_URL}/`} component={Registration} />
//            :  window.location.href.indexOf("hachvana")  > 0 ?  
//            <Route path={`${process.env.PUBLIC_URL}/`} component={HachvanaRegistration} />
//             : 
//           <Route path={`${process.env.PUBLIC_URL}/`} component={ImportersRegistration} />

//            } 

//         </Switch>   
//         {/* {loading ? <CircularProgress className="progressBar" /> : null}    */}
//       </Fragment>
//     );
//   }
// };

export default RoutingManager;
